import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { FaFileAlt } from 'react-icons/fa';
import "../css/quick-move-ins.css";

class QuickMoveInsPage extends React.Component {
  render(){
    const {data} = this.props;

    // console.log('data', data);

    const moveIns = data.allContentfulQuickMoveIn.edges;

    console.log(moveIns);

    return (
      <Layout>
        <SEO title="Plans" />
        <div className="container">

          <div className="row">
            <div className="col-sm mt-auto">
              <h1 className="mt-4 mb-0 qmi-title">Quick Move-Ins</h1>
            </div>
          </div>

          <div className="row plan-container mb-4">

        {moveIns.map(({ node }) => {
          return (
            <div className="col-sm-4 plan-col" key={node.title}>
              
              <h4>{node.title}</h4>

              {node.underContract || node.sold ? (
                  <div className="qmi-banner">
                    {node.sold ? 'SOLD' : 'UNDER CONTRACT' }
                  </div>
                ) : null}

              <Img alt="" fluid={node.thumbnail.fluid} />
              <div>
                <a href={node.brochure.file.url} target="_blank" rel="noopener noreferrer"><button type="button" className="btn btn-success btn-lg mt-3">BROCHURE <FaFileAlt /></button></a>
              </div>
            </div>
          )
        })}

      </div>

    </div>

      </Layout>
    )
  }
};

export default QuickMoveInsPage;

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
      author
    }
  }
  allContentfulQuickMoveIn {
      edges {
        node {
          title
          underContract
          sold
          thumbnail {
            fluid {
              ...GatsbyContentfulFluid
            }
            file {
              url
              fileName
              contentType
            }
          }
          brochure {
            file {
              url
            }
          }
        }
      }
    }
  }`
